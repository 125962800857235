import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enTranslation from "./language/en/translation.json";
import koTranslation from "./language/ko/translation.json";

i18n
  .use(i18nBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: localStorage.getItem("lang"),
    lng: localStorage.getItem("lang"),
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: enTranslation,
      },

      ko: {
        translation: koTranslation,
      },
    },
  });

export default i18n;
