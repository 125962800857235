import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getPartnerData } from "../../services/partners";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export const Partner = ({searchQuery}) => {
  const { t } = useTranslation();
  const [record, setRecord] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const filteredPartners = record.filter((partner) =>
  partner.heading.toLowerCase().includes(searchQuery) 
  // ||
  // partner.description.toLowerCase().includes(searchQuery)
);

  const getData = async () => {
    let lang = localStorage.getItem("lang");
    if (lang) {
      let data = { lang };
      const result = await getPartnerData(data);
      if (result.status) {
        setRecord(result.data);
      } else {
      }
    }
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <section class="lastes-news pt-5 pb-5" id="partner">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="lastes-left center-text">
                <h2> {t("Our Partners")} </h2>
                <p>{record && record[0]?.heading}</p>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="news-slider">
                <div class="slider autoplay-two">
                  <Carousel
                    responsive={responsive}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    {record &&
                      record.map((item, index) => (
                        <div
                          key={index}
                          style={{ textAlign: "center", padding: "20px" }}
                          className="news-slider-in"
                        >
                          <img
                            src={item.partner_image}
                            alt={`Slide ${index + 1}`}
                            // style={{
                            //   width: "100%",
                            //   height: "auto",
                            //   borderRadius: "10px",
                            // }}
                            className="img-fluid"
                          />
                        </div>
                      ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
