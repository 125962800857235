import * as opsService from "./Ops";
import {

  getboutUsApi,
 
} from "../Constent/Api";


const getAboutUsData = async (lang) => {
  let result = await opsService.getData(getboutUsApi, lang);
  return result;
};



export {  getAboutUsData };
