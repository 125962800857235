import React, { useState, useRef, useEffect } from "react";
import { InputValid } from "../validations/InputValid";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { loginUser } from "../../services/user";
export const Login = () => {
  const canvasRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [card, setCard] = useState("");
  const [cardErr, setCardErr] = useState("");
  const [password, setpassword] = useState("");
  const [passwordErr, setpasswordErr] = useState("");
  const [captchaText, setCaptchaText] = useState("");
  const [userCaptch, setUserCaptch] = useState("");
  const [userCaptchErr, setUserCaptchErr] = useState("");

  // const handlechange = (e) => {
  //   let { name, value } = e.target;
  //   console.log(name, value);
  //   if (name == "name") {
  //     setName(value);
  //     const err = InputValid(name, value);
  //     setNameErr(err);
  //   }
  //   if (name == "password") {
  //     setpassword(value);
  //     const err = InputValid(password, value);
  //     setpasswordErr(err);
  //   }
  // };

  // useEffect(() => {
  //   if (contactCategory && contactCategory.length > 0) {
  //     setCategoryId(contactCategory[0].id);
  //   }
  // }, [contactCategory]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    initializeCaptcha(ctx);
  }, []);

  const generateRandomChar = (min, max) =>
    String.fromCharCode(Math.floor(Math.random() * (max - min + 1) + min));

  const generateCaptchaText = () => {
    let captcha = "";
    for (let i = 0; i < 3; i++) {
      captcha += generateRandomChar(65, 90);
      captcha += generateRandomChar(97, 122);
      captcha += generateRandomChar(48, 57);
    }
    return captcha
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");
  };

  const drawCaptchaOnCanvas = (ctx, captcha) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const textColors = ["rgb(130,130,130)", "rgb(130,130,130)"];
    const letterSpace = 150 / captcha.length;
    for (let i = 0; i < captcha.length; i++) {
      const xInitialSpace = 25;
      ctx.font = "20px Roboto Mono";
      ctx.fillStyle = textColors[Math.floor(Math.random() * 2)];
      ctx.fillText(
        captcha[i],
        xInitialSpace + i * letterSpace,

        // Randomize Y position slightly
        Math.floor(Math.random() * 16 + 25),
        100
      );
    }
  };

  const initializeCaptcha = (ctx) => {
    setUserCaptch("");
    const newCaptcha = generateCaptchaText();
    setCaptchaText(newCaptcha);
    drawCaptchaOnCanvas(ctx, newCaptcha);
  };

  // useEffect(() => {
  //   const scrollToRef = (ref) => {
  //     if (ref.current) {
  //       ref.current.scrollIntoView({ behavior: "smooth" });
  //     }
  //   };

  //   switch (location.hash) {
  //     case "#Contact":
  //       scrollToRef(aboutUsRef);
  //       break;
  //     //   case "#products":
  //     //     scrollToRef(productsRef);
  //     //     break;
  //     //   case "#pricing":
  //     //     scrollToRef(pricingRef);
  //     //     break;
  //     //   case "#contact":
  //     //     scrollToRef(contactUsRef);
  //     //     break;
  //     default:
  //       break;
  //   }
  // }, [location.hash]);

  const handleChnage = (e) => {
    const { name, value } = e.target;
    // if (name === "categoryRadios") {
    //   setCategoryId(value);
    // }
    if (name === "card") {
      setCard(value);
      const err = InputValid(name, value);
      setCardErr(err);
    }
    if (name === "password") {
      setpassword(value);
      const err = InputValid(name, value);
      setpasswordErr(err);
    }
    console.log(name, value);
    if (name === "captch") {
      setUserCaptch(value);
      const err = InputValid(name, value);
      setUserCaptchErr(err);
    }
    // if (name === "message") {
    //   setMessage(value);
    //   const err = InputValid(name, value);
    //   setMessageErr(err);
    // }
  };
  const handleReset = () => {
    // setMessage("");
    setUserCaptch("");
    setCard("");
    setpassword("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const checkCard = InputValid("card", card);

    const checkPassword = InputValid("password", password);
    const checkUserCaptch = InputValid("userCaptch", userCaptch);
    if (checkCard) {
      setCardErr(checkCard);
      return false;
    }
    if (checkPassword) {
      setpasswordErr(checkPassword);
      return false;
    }

    if (checkUserCaptch) {
      setUserCaptchErr(checkUserCaptch);
      return false;
    }
    if (userCaptch != captchaText) {
      setUserCaptchErr("Invalid captcha code");
      return false;
    }
    const data = {
      card,
      password,
    };
    const result = await loginUser(data);

    if (result.status) {
      toast.dismiss();
      toast.success(result.message);
      // setDisable(false);
      handleReset("");
      navigate("/");
    } else {
      toast.dismiss();
      toast.error(result.message);
    }
  };
  return (
    <>
      <div className="register_page">
        <div className="container22">
          <div className="text-center mb-4">
            <img src="/assets/img/logo.png" className="logo" />
          </div>
          <form>
            <div className="">
              <h4>Card Id</h4>
              <div className="input-group2 input-group-icon">
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Enter card id"
                    value={card}
                    onChange={handleChnage}
                    name="card"
                  />{" "}
                  <div className="input-icon">
                    <i className="fa fa-user" />
                  </div>
                </div>
                <span className="text-danger">{cardErr}</span>
              </div>
            </div>
            <div className="">
              <h4>Password</h4>
              <div className="input-group2 input-group-icon">
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Enter password"
                    value={password}
                    onChange={handleChnage}
                    name="password"
                  />
                  <div className="input-icon">
                    <i className="fa fa-user" />
                  </div>
                </div>
                <span className="text-danger">{passwordErr}</span>
              </div>
            </div>
            <div className="form-group">
              <div className="wrapper">
                <canvas ref={canvasRef} width="200" height="70"></canvas>
                <button
                  id="reload-button"
                  className="btn btn-primary"
                  onClick={() =>
                    initializeCaptcha(canvasRef.current.getContext("2d"))
                  }
                  type="button"
                >
                  Reload
                </button>
              </div>
              <br />
              <h4>Enter Captcha Code*</h4>
              <div className="input-group2 input-group-icon">
                <input
                  type="text"
                  className="form-control"
                  name="captch"
                  onChange={handleChnage}
                  value={userCaptch}
                />
                <span className="text-danger">{userCaptchErr}</span>
              </div>
            </div>
            <div className="">
              <div className="input-group2">
                <button className="btn" type="submit" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
