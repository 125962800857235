import * as opsService from "./Ops";
import {

  getNewsApi,

} from "../Constent/Api";


const getNewsData = async (lang) => {
  let result = await opsService.getData(getNewsApi, lang);
  return result;
};



export {  getNewsData };