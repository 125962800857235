import React from "react";
import { useTranslation } from "react-i18next";
export const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      {" "}
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-minue">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <div class="block-foot">
              <img src="/assets/img/logo.png" />

              <label>
                <a href="javascript:void(0);">
                  <img src="/assets/img/facebook.svg" />
                </a>
                <a href="javascript:void(0);">
                  <img src="/assets/img/twitter.svg" />
                </a>
                <a href="javascript:void(0);">
                  <img src="/assets/img/instagram.svg" />
                </a>
                <a href="javascript:void(0);">
                  <img src="/assets/img/send.svg" />
                </a>
              </label>
            </div>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div class="block-foot">
                  <h4> {t("Copyright")}</h4>
                  <em>
                    <a href="javascript:void(0);"> {t("Privacy")}</a>
                    <a href="javascript:void(0);"> {t("Team")}</a>
                    <a href="javascript:void(0);"> {t("Road map")}</a>
                  </em>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div class="block-foot">
                  <h4> {t("Support")}</h4>
                  <em>
                    <a href="javascript:void(0);"> {t("Announcements")}</a>
                    <a href="javascript:void(0);"> {t("FAQ")}</a>
                    <a href="javascript:void(0);"> {t("Your feedback")}</a>
                  </em>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div class="block-foot">
                  <h4>  {t("Contact")}</h4>
                  <em>
                    <a href="javascript:void(0);"> {t("Telegram")}</a>
                    <a href="javascript:void(0);"> {t("E-mail")} </a>
                    <a href="javascript:void(0);"> {t("Subscribe")}</a>
                  </em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
