import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Footer } from "../widgets/Footer";
import { Partner } from "./pages/Partner";
import { News } from "./pages/News";
import { Faq } from "./pages/Faq";
import { Header } from "../widgets/Header";
import { getAboutUsData } from "../services/aboutUs";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getContent } from "../services/content";
import { useNavigate } from "react-router-dom";
import { getFaq } from "../services/faq";
import { getNewsData } from "../services/news";
export const Home = () => {
  const { t } = useTranslation();

  const [show, setShow] = useState(true);
  const [record, setRecord] = useState([]);

  const [contentData, setContentData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  console.log(searchQuery, "searchQuery");

  useEffect(() => {
    getData();
 
  }, []);

  const getData = async () => {
    let lang = localStorage.getItem("lang");
    if (lang) {
      let data = { lang };
      const result = await getAboutUsData(data);
      if (result.status) {
        setRecord(result.data);
      } else {
      }
      const resp = await getContent(data);
      if (resp.status) {
        setContentData(resp.data);
      } else {
      }
    }
  };
  // ===============search==============

  const [searchTerm, setSearchTerm] = useState("");

  const elementRefs = useRef([]);

 


  const handleShow = (value) => {
    setShow(true);
  };



  // searchh-========
  function searchAndScroll() {
    let word = searchTerm;
    console.log(word);

    // Remove previous highlights and restore original content
    document.querySelectorAll("[data-original-html]").forEach((el) => {
      el.innerHTML = el.dataset.originalHtml; // Restore original HTML content
      delete el.dataset.originalHtml; // Remove the data attribute
    });

    // If no search term is provided, stop here
    if (!word) {
      return;
    }

    // Search for the word
    const textElements = document.querySelectorAll(
      "p, h1, h2, h3, h4, h5, h6, a, button, span"
    );
    textElements.forEach((el) => {
      // Only handle text nodes
      if (el.nodeType === Node.ELEMENT_NODE) {
        Array.from(el.childNodes).forEach((childNode) => {
          if (childNode.nodeType === Node.TEXT_NODE) {
            const textContent = childNode.textContent;
            const lowerCaseText = textContent.toLowerCase();
            const lowerCaseWord = word.toLowerCase();
            const index = lowerCaseText.indexOf(lowerCaseWord);

            if (index !== -1) {
              // Store the original inner HTML in a data attribute
              el.dataset.originalHtml = el.innerHTML;

              // Create highlighted HTML
              const before = textContent.substring(0, index);
              const highlight = textContent.substring(
                index,
                index + word.length
              );
              const after = textContent.substring(index + word.length);

              // Replace text node with new nodes
              const newNodes = [
                document.createTextNode(before),
                createHighlightSpan(highlight),
                document.createTextNode(after),
              ];

              // Replace the old text node
              const parent = childNode.parentNode;
              newNodes.forEach((node) => parent.insertBefore(node, childNode));
              parent.removeChild(childNode);

              // Scroll to the highlighted word
              const highlightedElement = parent.querySelector(".highlight");
              if (highlightedElement) {
                highlightedElement.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }
            }
          }
        });
      }
    });

    function createHighlightSpan(text) {
      const span = document.createElement("span");
      span.className = "highlight";
      span.textContent = text;
      return span;
    }
  }

  return (
    <>
      <div id="particles-js"></div>

      <Header />
      

      <section class="home-banner pt-5 pb-5" id="home">
        <div class="top-fix"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
              <div class="banner-left">
              
                <h1 ref={(el) => (elementRefs.current[0] = el)}>
                  {contentData && contentData[0]?.heading}
                </h1>
                <p ref={(el) => (elementRefs.current[1] = el)}>
                  {contentData && contentData[0]?.paragraph}
                </p>

                <div className="serachInput">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search..."
                />
                <button onClick={searchAndScroll} class="btn btn-primary">
                  Search
                </button>

                <div>
                  <p ref={(el) => (elementRefs.current[0] = el)}></p>
                </div>
              </div>
              </div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12">
              <div class="banner-right">
                <img src="/assets/img/slider-img1.png" class="working_img" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Faq />

      <News />

      <Partner />
      <section class="about-bg" id="about">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="map-img center-text">
                <img src="/assets/img/dotted-map.png" class="map-img-view" />
                <div class="about-top center-text">
                  <h3>{t("About Us")}</h3>
                  <p ref={(el) => (elementRefs.current[5] = el)}>
                    {record && record[0]?.description}
                  </p>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </section>
    </>
  );
};
