import * as opsService from "./Ops";
import { getContentApi } from "../Constent/Api";

const getContent = async (lang) => {
  let result = await opsService.getData(getContentApi, lang);
  return result;
};

export { getContent };




