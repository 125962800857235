import { BaseUrl } from "./baseUrl";

export const getFaqApi = BaseUrl + "/get-faq";

// ===============about us =========================//

export const getboutUsApi = BaseUrl + "/get-about-us";

// ===============news =========================//

export const getNewsApi = BaseUrl + "/get-news";

// ===============partner =========================//

export const getPartnerApi = BaseUrl + "/get-partner";

// ===============partner =========================//

export const getContentApi = BaseUrl + "/get-content";

// ===============register================
export const RegisterUserApi = BaseUrl + "/insert-google-form";

export const loginUserApi = BaseUrl + "/login-user";









