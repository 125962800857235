import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getNewsData } from "../../services/news";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export const News = ({ searchQuery }) => {
  const [show, setShow] = useState(false);

  const [dis, setDis] = useState("");
  const handleShow = (value) => {
    setShow(true);
    setDis(value)
  };
  const handleClose = () => {
    setShow(false);
  };

  const { t } = useTranslation();
  const [record, setRecord] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let lang = localStorage.getItem("lang");
    if (lang) {
      let data = { lang };
      const result = await getNewsData(data);
      if (result.status) {
        setRecord(result.data);
      } else {
      }
    }
  };

  // const filteredNews = record.filter(
  //   (news) =>
  //     news.main_heading.toLowerCase().includes(searchQuery) ||
  //     news.heading.toLowerCase().includes(searchQuery) ||
  //     news.description.toLowerCase().includes(searchQuery)
  // );

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <section class="lastes-news pt-100 pb-5" id="blockchain">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <div class="lastes-left">
                {/* <b>Update</b> */}
                <h2> {t("Latest News")}</h2>
                <p>{record && record[0]?.main_heading}</p>
              </div>
            </div>

            <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">
              <div class="news-slider">
                <div class="slider autoplay">
                  <Carousel
                    responsive={responsive}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    {record &&
                      record.map((item, index) => (
                        <div
                          key={index}
                          style={{ textAlign: "center", padding: "20px" }}
                          className="news-slider-in"
                        >
                          <a href={item?.link_url}>
                            <img
                              src={item.news_image}
                              alt={`Slide ${index + 1}`}
                              className="img-fluid"
                            />
                          </a>
                          <h3 class="block-heading">{item?.heading}</h3>
                          <p class="block-ellipsis">{item?.description}</p>
                          <a
                            onClick={() => handleShow(item?.description)}
                            className="readBtn"
                          >
                            Read More...
                          </a>
                        </div>
                      ))}
                  </Carousel>
                </div>
              </div>
              {/* <p>{dis && dis ? dis : "jhuj"}</p> */}
              {show && (
                <Modal show={show} onHide={handleClose}>
                  <Modal.Body>
                    <p>{dis && dis ? dis : ""}</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}

              {/* <Modal  show={show} >
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" >
            Close
          </Button>
         
        </Modal.Footer>
      </Modal> */}
              {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

              {/* <Modal show={show&&show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Woohoo, you are reading this text in a modal!
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleClose}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
