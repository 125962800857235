import * as opsService from "./Ops";
import { RegisterUserApi, loginUserApi } from "../Constent/Api";

const registerUser = async (lang) => {
  let result = await opsService.postdata(RegisterUserApi, lang);
  return result;
};
const loginUser = async (lang) => {
  let result = await opsService.postdata(loginUserApi, lang);
  return result;
};

export { registerUser, loginUser };
