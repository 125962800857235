import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ImageValid } from "../validations/ImageValid";
import { InputValid } from "../validations/InputValid";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { registerUser } from "../../services/user";


export const Register = () => {
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [role, setRole] = useState("");
  const [roleErr, setRoleErr] = useState("");
  const [imageURL, setImageUrl] = useState("");
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [country, setcountry] = useState("");
  const [country_code, setcountry_code] = useState("");
  const [number, setnumber] = useState("");
  const [description, setdescription] = useState("");
  const [company_name, setcompany_name] = useState("");
  const [address, setaddress] = useState("");
  const [pincode, setpincode] = useState("");
  const [mobile_number, setMobile_number] = useState("+82");
  const [countryErr, setcountryErr] = useState("");
  const [country_codeErr, setcountry_codeErr] = useState("");
  const [numberErr, setnumberErr] = useState("");
  const [descriptionErr, setdescriptionErr] = useState("");
  const [company_nameErr, setcompany_nameErr] = useState("");
  const [addressErr, setaddressErr] = useState("");
  const [pincodeErr, setpincodeErr] = useState("");
  const [fileName, setFileName] = useState("");
  const handlechange = (e) => {
    let { name, value } = e.target;
    console.log(name, value);
    if (name === "name") {
      setName(value);
      const err = InputValid(name, value);
      setNameErr(err);
    }
    if (name === "number") {
      setnumber(value);
      const err = InputValid(name, value);
      setnumberErr(err);
    }
    // if (name === "country") {
    //   setcountry(value);
    //   const err = InputValid(name, value);
    //   setcountryErr(err);
    // }
    if (name === "description") {
      setdescription(value);
      const err = InputValid(name, value);
      setdescriptionErr(err);
    }
    if (name === "company_name") {
      setcompany_name(value);
      const err = InputValid(name, value);
      setcompany_nameErr(err);
    }
    if (name === "address") {
      setaddress(value);
      const err = InputValid(name, value);
      setaddressErr(err);
    }
    if (name === "pincode") {
      setpincode(value);
      const err = InputValid(name, value);
      setpincodeErr(err);
    }
  };
  const handlechangeimage = (e) => {
    let { name } = e.target;
    const file = e.target.files[0];
    const image = e.target.files[0];
    if (name === "image") {
      setImage(image);
      setFileName(file.name);
      // setImageUrl(URL.createObjectURL(image));
      // let checkImage = ImageValid(name, image);
      // setImageErr(checkImage);
    }
  };

  const handleOnChanges = (value, country) => {
    setMobile_number(value);

    let adjustedMobile = value.startsWith(country.dialCode)
      ? value.replace(country.dialCode, "")
      : value;

    if (!adjustedMobile) {
      setnumberErr("Mobile Number is required");
    } else {
      setnumberErr("");
    }
    setnumber(adjustedMobile);
    setcountry_code("+" + country.dialCode);
  };
  const onsubmit = async (e) => {
    e.preventDefault();
    const checkName = InputValid("name", name);
    if (checkName) {
      setNameErr(checkName);
      return false;
    }
    const numbere = InputValid("number", number);
    if (numbere) {
      setnumberErr(numbere);
      return false;
    }

    const checkroless = InputValid("description", description);

    if (checkroless) {
      setdescriptionErr(checkroless);
      return false;
    }
    const companyNameErr = InputValid("company_name", company_name);
    if (companyNameErr) {
      setcompany_nameErr(companyNameErr);
      return false;
    }
    const addressErr = InputValid("address", address);
    if (addressErr) {
      setaddressErr(addressErr);
      return false;
    }
    const pincodeErr = InputValid("pincode", pincode);
    if (pincodeErr) {
      setpincodeErr(pincodeErr);
      return false;
    }
    // console.log(image,"image");
    // let checkImage = ImageValid("document", image);
    // console.log(checkImage,"checkImage");
    // if (checkImage) {
    //   setImageErr(checkImage);
    //   return false;
    // }

    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("country", country);
    formdata.append("document", image);

    formdata.append("country_code", country_code);
    formdata.append("number", number);
    formdata.append("description", description);
    formdata.append("company_name", company_name);
    formdata.append("address", address);
    formdata.append("pincode", pincode);
    const resp = await registerUser(formdata);

    if (resp.status) {
      setName("");
      setMobile_number("");
      setnumber("");
      setaddress("");
      setcompany_name("");
      setpincode("");
      setImage("");
      toast.dismiss();
      setdescription("");
      toast.success(resp.message);
      // handleClose();
    } else {
      toast.dismiss();
      toast.error(resp.message);
    }
  };

  return (
    <>
      <div className="register_page">
        <div className="container22">
          <div className="text-center mb-4">
            <img src="/assets/img/logo.png" className="logo" />
          </div>
          <form>
            <div className="">
              <h4>Account</h4>
              <div className="input-group2 input-group-icon">
              <div className="position-relative">
                <input
                  type="text"
                  placeholder="Full Name"
                  value={name}
                  onChange={handlechange}
                  name="name"
                />  <div className="input-icon">
                  <i className="fa fa-user" />
                </div></div>
                <span className="text-danger">{nameErr}</span>
              
              </div>
              <h4>Number</h4>
            
              <div className="input-group2 ">
              <PhoneInput
                key={"phoneInput"}
                country="South Korea"
                value={mobile_number}
                onChange={handleOnChanges}
                className="input_item"
                placeholder="Mobile Number"
                countryCodeEditable={false}
                enableSearch={true}
                inputProps={{
                  autoFocus: true,
                  name: "mobile_number",
                }}
                // disabled={disableGetCode}
              />
              <span className="text-danger">{numberErr}</span>
              </div>
         
              <h4>Description</h4>
              <div className="input-group2 input-group-icon">
              <div className="position-relative">
                <textarea
                className="d-block"
                  type="email"
                  placeholder="Description"
                  defaultValue={""}
                  value={description}
                  onChange={handlechange}
                  name="description"
                />
                <div className="input-icon">
                  <i className="fa fa-envelope" />
                </div>
                </div>
                <span className="text-danger">{descriptionErr}</span>
                
              </div>
              <h4>Company Name</h4>
              <div className="input-group2 input-group-icon">
              <div className="position-relative">
                <input
                  type="Address"
                  placeholder="Company Name"
                  value={company_name}
                  onChange={handlechange}
                  name="company_name"
                />{" "}  <div className="input-icon">
                  <i className="fa fa-building-o" />
                </div>
                </div>
                <span className="text-danger">{company_nameErr}</span>
              
              </div>
              <h4>Address</h4>
              <div className="input-group2 input-group-icon">
                <input
                  type="Address"
                  placeholder="Address"
                  value={address}
                  onChange={handlechange}
                  name="address"
                />{" "}
                <span className="text-danger">{addressErr}</span>
                <div className="input-icon">
                  <i className="fa fa-map-signs" />
                </div>
              </div>
              <h4>Pincode</h4>
              <div className="input-group2 input-group-icon">
                <input
                  type="number"
                  placeholder="******"
                  value={pincode}
                  onChange={handlechange}
                  name="pincode"
                />{" "}
                <span className="text-danger">{pincodeErr}</span>
                <div className="input-icon">
                  <i className="fa fa-th" />
                </div>
              </div>
              <h4>Document Upload</h4>
              <div className="input-group2 input-group-icon document_ipload   position-relative">
                <input
                  type="file"
                  placeholder="******"
                  // value={image}
                  onChange={handlechangeimage}
                  name="image"
                />{" "}
                <i className="fa fa-plus  upload_icon" />
                <img
                  style={{ width: "100px" }}
                  // src={image}
                  className="img-fluid"
                  alt={fileName}
                />
              </div>
              <span className="text-danger">{imageErr}</span>
            </div>
            {/* <div className="row">
      <h4>Terms and Conditions</h4>
      <div className="input-group">
        <input id="terms" type="checkbox" />
        <label htmlFor="terms">
          I accept the terms and conditions for signing up to this
          service, and hereby confirm I have read the privacy policy.
        </label>
      </div>
    </div> */}
            <div className="">
              <div className="input-group2">
                <button className="btn" type="submit" onClick={onsubmit}>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
