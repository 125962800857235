import React, { useEffect, useState,useRef } from "react";
import { getFaq } from "../../services/faq";
import { useTranslation } from "react-i18next";
export const Faq = () => {
 
  const { t } = useTranslation();
  const [record, setRecord] = useState([]);

  const elementRefs = useRef([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let lang = localStorage.getItem("lang");
    if (lang) {
      let data = { lang };
      const result = await getFaq(data);
      if (result.status) {
        setRecord(result.data);
      } else {
      }
    }
  };

  return (
    <>
      {" "}
      <section class="lastes-news" >
        <div class="container">
          <div class="row">
            <div class="lastes-left text-center">
              <b>
                {" "}
                <h2>{t("FAQ")}</h2>
              </b>
              {/* <h2> {t("Introduction to Blockchains")}</h2> */}
              {/* <p>
                  Pellentesque nulla consequat parturient ultricies luctus
                  conubia taciti bibendum.
                </p> */}
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12"></div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="news-slider-faq">
                <div class="d-flex align-items-start">
                  <ul
                    class="nav nav-pills flex-column nav-pills border-end border-3 me-3 align-items-end"
                    id="pills-tab"
                    role="tablist"
                   
                  >
                    {record &&
                      record.map((item, index) => {
                        return (
                          <li
                            className="nav-item"
                            role="presentation"
                            key={index} 
                           
                          >
                            <button
                              className={`nav-link fw-semibold ${
                                index === 0 ? "active" : ""
                              } position-relative`}
                              id={`${index + 1}-label-tab`}
                              data-bs-toggle="pill"
                              data-bs-target={`#${index + 1}-label`}
                              type="button"
                              role="tab"
                              aria-controls={`${index + 1}-label`}
                              aria-selected={index === 0 ? "true" : "false"}
                            >
                              {item?.question}
                            </button>
                          </li>
                        );
                      })}
                  </ul>

                  <div class="tab-content w-100" id="pills-tabContent">
                    {record &&
                      record.map((item, index) => {
                        return (
                          <div
                            className={`tab-pane fade ${
                              index === 0 ? "show active" : ""
                            }`}
                            id={`${index + 1}-label`}
                            role="tabpanel"
                            aria-labelledby={`${index + 1}-label-tab`}
                            key={index}
                          >
                            <h2  ref={(el) => (elementRefs.current[2] = el)}>{item?.question}</h2>
                            <p  ref={(el) => (elementRefs.current[3] = el)}>{item?.answer}</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
