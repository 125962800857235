import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import ChangeLang from "../components/language/ChangeLang";
import { Link } from "react-router-dom";
import Search from "../components/search/Search";
import { useNavigate } from "react-router-dom";
export const Header = ({ onSearch }) => {
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  console.log(searchQuery, "searchQuery");
  const navigate = useNavigate();
  // const handleSearch = (query) => {
  //   setSearchQuery(query.toLowerCase());
  // };
  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  // =======================searrch
  const [searchTerm, setSearchTerm] = useState("");
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const elementRefs = useRef([]);

  const handleSearch = () => {
    // Clear previous highlights
    elementRefs.current.forEach((ref) => {
      if (ref) ref.style.backgroundColor = "";
    });

    // Highlight the matched paragraph(s)
    const matchingElements = elementRefs.current.filter((ref) =>
      ref?.textContent?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (matchingElements.length > 0) {
      // Scroll to the first match and highlight it
      setCurrentMatchIndex(0);
      matchingElements[0].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      matchingElements[0].style.backgroundColor = "yellow";
    }
  };

  const handleNextMatch = () => {
    const matchingElements = elementRefs.current.filter((ref) =>
      ref?.textContent?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (matchingElements.length > 0) {
      // Clear current highlight
      if (matchingElements[currentMatchIndex]) {
        matchingElements[currentMatchIndex].style.backgroundColor = "";
      }

      // Move to the next match
      const nextIndex = (currentMatchIndex + 1) % matchingElements.length;
      setCurrentMatchIndex(nextIndex);

      // Scroll to the next match and highlight it
      matchingElements[nextIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      matchingElements[nextIndex].style.backgroundColor = "yellow";
    }
  };

  // =========search=================
  const { t } = useTranslation();

  useEffect(() => {
    const initGTranslate = () => {
      if (window.gtranslateSettings) {
        const script = document.createElement("script");
        script.src = "https://cdn.gtranslate.net/widgets/latest/dwf.js";
        script.async = true;
        document.body.appendChild(script);
      }
    };

    initGTranslate();
  }, []);

  return (
    <>
      {" "}
      <header class="sticky">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <nav class="navbar navbar-expand-lg navbar-light">
                <div class="container-fluid">
                  <a class="navbar-brand" href="/">
                    <img src="/assets/img/logo.png" class="logo-head" />
                  </a>
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div
                    class="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                      <li class="nav-item">
                        <a class="nav-link" aria-current="page" href="#home">
                          {t("Home")}
                        </a>
                      </li>

                      <li class="nav-item">
                        <a
                          class="nav-link"
                          aria-current="page"
                          href="#blockchain"
                        >
                          {t("Blockchain")}
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" aria-current="page" href="#partner">
                          {t("Partners")}
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" aria-current="page" href="#about">
                          {t("About Us")}
                        </a>
                      </li>
                      {/* <div className="search-box">
                        <input
                          type="text"
                          value={query}
                          onChange={handleInputChange}
                          placeholder="Search..."
                        />
                        <button onClick={handleSearch} class="btn btn-primary">
                          Search
                        </button>
                      </div> */}
                      {/* <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search..."
                      />
                      <button onClick={handleSearch} class="btn btn-primary">Search</button>
                      <button onClick={handleNextMatch} class="btn btn-primary">Next Match</button>

                      <div>
                        <p ref={(el) => (elementRefs.current[0] = el)}>
                         
                        </p>
                      </div> */}

                      {/* <Search onSearch={onSearch} /> */}

                      <li class="nav-item">
                        <div class="gtranslate_wrapper"></div>
                      </li>
                    </ul>
                  </div>
                  <ChangeLang />
                  <Link to="/login" class="btn btn-primary">
                    {t("Login")}
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
